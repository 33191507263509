.Land {

  background-color:whitesmoke;

  font-family: poppinslight;
  color: white;
  align-items: center;

  


}


.pointer         { cursor: pointer; }



.text11 {
  
  font-family: Fredoka;
  color:#38b6ff;
font-size: 50px; 

}



.text12 {
  
  font-family: Fredoka;
  color:#38b6ff;
font-size: 50px; 

}



.text1 {

  color: black;

}

.text2 {

  color: #38b6ff;
  
}


.text3 {

  color: rgb(49, 49, 49);


}

.text4 {

  color: #38b6ff;
  
}


.nav-link {

  color: rgb(49, 49, 49);

}





h1 {

font-family: poppinsbold;

font-size: calc(14px + 5vmin);
font-weight: bolder;
}

h2 {
  font-family: poppinslight;
  font-size: calc(10px + 4vmin);
  font-weight: bolder;
}

h3 {
  font-family: poppinslight;
  font-size: calc(6px + 3vmin);
  
}

h4 {
  font-family: poppinslight;
  
  
}







.Land-header {
  padding-top: 15px;


  background-color:whitesmoke;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top-center;
  font-size: calc(10px + 2vmin);
  color: black;
}





.header-foot {
  padding-top: 20px;


  background-color:whitesmoke;


  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}




.table {

  color:black;

  border-color: grey;

  }
  




body {

  background-color:whitesmoke;

  

  background-position: bottom;
  background-repeat: no-repeat;

color:black;  
  
  
  padding: 0px;
  font-family: poppinslight;
  align-items: center;
  
  


}









.App-header {

  
  
  background-color: whitesmoke;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}



.form-control  {


  color:rgb(73, 73, 73);
  font-weight: 750;



  border-style:inset;
  border-color: transparent;

  background-color: rgb(214, 213, 213);


  border-radius: 5px;
  font-size:12px;

  

}







.form-control::placeholder {
  color: grey;  

  text-align: start;
  
}






.form-control:disabled  {


  color:rgb(73, 73, 73);
  font-weight: 750;


  background-color: rgb(214, 213, 213);




border-style:inset;
  border-color: transparent;
  
  border-radius: 5px;
  font-size: 12px;
}



.img-fluid-update:hover {

  cursor: pointer;
  
}  



.card {

border: none;
border-radius: 15px;
background-color: rgb(214, 213, 213);
color: #010B13;
font-family: poppinslight;



}



.card-info {

  padding: 20px;
  border-radius: 30px;

  background-color: white;

  color: #010B13;

  font-family: poppinslight;

  
  
  
  }
  







a {
  color: grey;
}






.label {
color: black;
text-align: start;
}




.card-body-history {

  background-color: transparent;
  
  white-space: nowrap;
  overflow: auto;
  text-overflow: ellipsis;


}




.card-body1 {

  

  
background-color: transparent;
    
  }


.card1 {
 

  border-radius: 15px;
  background-color: #010B13;
  color: whitesmoke;
  font-family: poppinslight;
  border-color:#010B13;
  
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
background-size: 550px;
}



.card-body {

  background-color: rgb(214, 213, 213);

  


border-radius: 15px;






}


.card-title {

  color: #010B13;

}





.img
{

  
border-radius: 5px;

}







.img-fluid-1
{

  
border-radius: 0px;

}



.table {

  color: black;
  font-size: small;
}



.btn {
  width: 145px;
  height: 50px;
  
  background-color: #0D6EFD;
  color:whitesmoke;
  border-radius: 10px;
  border-color: transparent;
  font-weight: bold;
  
  }
  

  .btn:hover {
    width: 145px;
    height: 50px;


    background-color: #297cfa;
    color:whitesmoke;
    border-radius: 10px;
    border-color: transparent;
    font-weight: bold;
    
    }
  

    







    






    .btn-pay {
      width: 330px;
  height: 50px;

  background-color: #0D6EFD;
      color:whitesmoke;
      border-radius: 10px;
      border-color: transparent;
      font-weight: bold;
      font-size: medium;
      }
      
    
      .btn-pay:hover {
        width: 330px;
        height: 50px;
        
        background-color: #297cfa;
        color:whitesmoke;
        border-radius: 10px;
        border-color: transparent;
        font-weight: bold;
        font-size: medium;
        }
    






    .card-collect {
      width: 360px;
      
   color:rgb(73, 73, 73);
   background-color: white;
      
   font-weight:750;
      
      border-style: solid;

      border-width: 1px;

      border-color:rgb(207, 206, 206);



      padding: 15px;
      border-radius: 15px;
      align-content:center;
      text-align: start;
      font-size: small;
      


      }

      
      .card-collect h1 {

        color:rgb(73, 73, 73);
        font-weight:750;

        font-size: small;
      
      }


      .form-control-dash  {


        border-color: transparent;
        color: black;  
        background-color: grey;
      
        border-radius: 5px;
        font-size: small;
      
               
      
      }
      
      
      
      
      
      
      
      .form-control-dash::placeholder {
        color: grey;  
      
        text-align: start;
        
      }
      
      
      
      
      
      
